

















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { IGroupMasterFilter, UserShortResource } from '@/store/types'
import MentorMastersModule from '@/store/modules/mentor/masters'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'

@Component({
  components: {
    LinkTabs,
  },
})
export default class MastersList extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private isLoaded = false

  private masterGroupId: number | null = this.currentMasterGroupID
  private filter: IGroupMasterFilter = GET_DEFAULT_TABLE_FILTER()

  private get tabs () {
    const list = []

    if (this.hasPermission(this.Permission.EDUCATION_EXERCISES_CHECK)) {
      list.push({
        name: 'Мои мастера',
        to: '/manager/education/masters/my',
      })
    }
    if (this.mentors.length || this.hasPermission(this.Permission.MENTOR_LIST_ALL)) {
      list.push({
        name: 'Мастера наставников',
        to: '/manager/education/masters/mentors',
      })
    }

    return list
  }

  private get mentors () {
    return MentorMastersModule.mentors
  }

  private mounted () {
    if (!this.mentors.length) {
      this.isLoaded = false
      MentorMastersModule.fetchMentors({
        // В табе "мастера наставников" в селекте "наставники" у старшего наставника должны быть только его наставники
        self: this.hasPermission(this.Permission.MENTOR_LIST_VIEW) && !this.hasPermission(this.Permission.MENTOR_LIST_ALL),
      })
        .then((response: UserShortResource[]) => {
          if (this.$route.name === 'manager.education.masters.list') {
            if (response.length || this.hasPermission(this.Permission.MENTOR_LIST_ALL)) {
              this.$router.replace({ name: 'manager.education.masters.list.mentors' })
            } else if (this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_CHECK, this.Permission.EDUCATION_EXERCISES_VIEW])) {
              this.$router.replace({ name: 'manager.education.masters.list.my' })
            } else {
              this.notifyError('У вас недостаточно прав для просмотра мастеров')
              this.$router.replace({ name: 'profile' })
            }
          }
        })
        .catch(this.notifyError)
        .finally(() => this.isLoaded = true)
    } else {
      this.isLoaded = true
      if (this.$route.name === 'manager.education.masters.list') {
        if (this.hasPermission(this.Permission.MENTOR_LIST_ALL)) {
          this.$router.replace({ name: 'manager.education.masters.list.mentors' })
        } else if (this.hasPermission(this.Permission.EDUCATION_EXERCISES_CHECK)) {
          this.$router.replace({ name: 'manager.education.masters.list.my' })
        }
      }
    }
  }

  private handleChangeFilter(filter: IGroupMasterFilter) {
    this.filter = filter
  }

  private handleChangeMasterGroup(masterGroupId: number) {
    this.masterGroupId = masterGroupId
  }

  private handleExport () {
    this.$bus.$emit('exportMastersList')
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Список мастеров',
    }
  }
}
